/** @jsxImportSource @emotion/react */
import "reset-css";
import Divider from "../components/Divider";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import "../utilities/Data";

export default function Organisation() {
  return (
    <Panel title="Au menu !" paddingBottom paddingSides lastUpdate="oui">
      <Divider />
      <Spacer />

      <Text center typo="subheading">
        Boissons
      </Text>
      <Spacer.Half />

      <Text center>☕ Café, thé</Text>
      <Text center>🥤 Jus de fruits, sodas, softs</Text>
      <Text center>🍺 Bières</Text>
      <Text center>🥃 Alcool forts</Text>
      <Spacer />
      <Divider />
      <Spacer />

      <Text center typo="subheading">
        Goûter, dès 15h
      </Text>
      <Spacer.Half />
      <Text center >🧋 Thé glacé maison</Text>
      <Text center >🍊 Eau aromatisée : Concombre, citron, orange</Text>
      <Text center >🧇 Financiers pistache</Text>
      <Text center >🍫 Brownie, Cannelés</Text>
      <Text center >🍬 Bonbons et viennoiseries pour les enfants</Text>
      <Spacer />
      <Divider />
      <Spacer />
      <Text center typo="subheading">
        Repas, autour de 20h
      </Text>
      <Spacer.Half />

      <Text center>🍾 Bulles à l'apéro</Text>

      <Spacer.Half />

      <Text center>🥪 Mini croques</Text>
      <Text center>🍔 Mini burger au confit de canard</Text>
      <Text center>🍗 Brochette de poulet mariné</Text>
      <Text center>🍤 Samoussas de bœuf à la thaï</Text>
      <Text center>🥟 Gyosa au poulet</Text>
      <Text center>🥣 Gaspacho de poivrons </Text>
      <Text center>🍄 Tartelette champignon</Text>
      <Text center>🧀 Cannelé au parmesan</Text>
      <Text center>🍆 Feuilleté tomate et aubergine grillé</Text>
      <Spacer.Half />

      <Text center>🍷 Vin rouge / vin blanc</Text>
      <Spacer.Half />

      <Text center>🍧 Tiramisu caramel au beurre salé</Text>
      <Text center>🍎 Tarte fine aux pommes </Text>
      <Text center>🍰 Cheese cake</Text>

      <Spacer />
      <Divider />
      <Spacer />

      <Text center typo="subheading">
        Service au plateau - Commande possible au bar
      </Text>
    </Panel >
  );
}
