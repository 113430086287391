/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import "reset-css";
import Divider from "../components/Divider";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import Css from "../utilities/Css";
import "../utilities/Data";
import Layout from "../utilities/Layout";
import useResponsive from "../utilities/useResponsive";

export default function Intro() {
  const containerCss = useResponsive({
    [Layout.B]: css``,
    bigger: css`
      height: 90vh;
      ${Css.centerContent};
      flex-direction: column;
      justify-content: center;
    `,
  });

  const nomsCss = useResponsive({
    [Layout.B]: css`
      width: 90vw;
      margin: auto;
      display: block;
    `,
    bigger: css`
      width: 30vw;
    `,
  });
  return (
    <div css={containerCss}>
      <Spacer.Double />
      <img src="noms.png" css={nomsCss} />
      <Spacer />
      <Divider />
      <Spacer />
      <Text typo="heading" center>
        Samedi 10 septembre 2022
      </Text>
      <Spacer.Double />
    </div>
  );
}
