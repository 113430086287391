/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'leaflet/dist/leaflet.css';
import "reset-css";
import Button from '../components/Button';
import Content from '../components/Content';
import Divider from '../components/Divider';
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import contextualize from '../utilities/contextualize';
import "../utilities/Data";
import Data from '../utilities/Data';

export default function FullPlanning() {

  const mapCss = css`
  align-self : stretch ; height : 300px ;
  .leaflet-container {
    height: 100%;
}`

  const planCss = css`
  width : 100%
`

  const infos = Data.useInfos();

  return (
    <Panel title="Come on !" paddingBottom lastUpdate='oui'>
      <Content>
        <Text center>
          Nous {contextualize(infos, { plural: "vous attendons", one: "t'attendons" })}
        </Text>
        <Spacer />
        <Text typo="subheading" center>
          Ce samedi 10 septembre 2022
        </Text>
        <Spacer.Half />
        <Text typo="subheading" center>
          A partir de 15h
        </Text>
        <Spacer.Half />
        <Text typo="subheading" center>
          au restaurant
        </Text>
        <Spacer.Half />
        <Text typo="subheading" center>
          The Frenchie
        </Text>
        <Spacer />
        <Text center>
          Le restaurant est situé à l'intersection de la rue Kervégan et de la rue Bon Secours, dans le centre de Nantes
        </Text>

      </Content>



      <Spacer />
      <a href={"/plan.jpg"} target="_blank">
        <img src="/plan.jpg" css={planCss} alt="Plan" />
      </a>
      <Spacer />
      <Button target="_blank" href="https://goo.gl/maps/hgqKovCfjGFeEc566" label='Google Maps' />
      <Spacer />
      <Content>
        <Text center>
          Le restaurant fermera à 2h du matin. Si  {contextualize(infos, { plural: "vous souhaitez", one: "tu souhaites" })} poursuivre la soirée,  {contextualize(infos, { plural: "manifestez-vous", one: "manifeste-toi" })} (voir bloc ci-dessous)
        </Text>
      </Content>

      <Spacer />

      <Content>
        <Divider />
        <Spacer />
        <Text center typo="subheading">Venir en train</Text>
        <Spacer.Half />
        <Text center>De la gare (sortie Nord), tramway ligne 1 puis 2 arrêts jusqu'à Bouffay. Les transports en commun à Nantes sont gratuits le samedi et le dimanche.</Text>
        <Spacer />
        <Divider />
        <Spacer />
        <Text center typo="subheading">Venir en voiture, en se garant à l'entrée de Nantes</Text>
        <Spacer.Half />
        <Text center> Stationnement gratuit en parking « P+R » en entrée de Nantes puis transports en commun gratuits.</Text><Spacer />
        <Text center>Prendre un ticket à l’entrée du parking et le valider à l’aller et au retour dans les bus et les trams. Au moment de la sortie, rien ne sera facturé (dans la limite de 24h).</Text><Spacer />
        <Text center>S’arrêter à Hotel Dieu, Commerce ou Bouffay selon la ligne. Fin de service des transports en commun autour de 2h.</Text>
        <Spacer />
        <Divider />
        <Spacer />
        <Text center typo="subheading">Venir en voiture, en se garant dans Nantes</Text>
        <Spacer.Half />
        <Text center> Parking souterrain de Feydeau (environ 12€, de 15h à 2h, gratuit au-delà)</Text><Spacer />
        <Text center> Parking voirie de Gloriette (environ 14€, de 15h à 2h, gratuit au-delà)</Text>
        <Spacer.Half />
      </Content>

    </Panel >
  );
}
