/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useState } from "react";
import "reset-css";
import Button from "../components/Button";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import contextualize from "../utilities/contextualize";
import Css from "../utilities/Css";
import "../utilities/Data";
import Data from "../utilities/Data";
import pluralize from "../utilities/pluralize";
import Typo from "../utilities/Typo";

export default function Message() {
  const infos = Data.useInfos();

  const [message, setMessage] = useState(infos.invitation.fields.Message || "");

  const textareaCss = css`
    ${Typo.body}
    width : 100%;
    height: 200px;
    outline: 2px solid rgba(255, 255, 255, 0.4);
    outline-offset: -2px;
    border: none;
    border-radius: 4px;
    background: #e0eafc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 10px;
    text-align: center;
    width: 100%;
  `;

  const formCss = css`
    ${Css.container}
  `;

  const responseCss = css`
    ${Css.container}
    background: #e0eafc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    text-align: center;
  `;

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await Data.updateInvitation(infos.invitation.id, {
        Message: message,
        Réponse: "",
      });
      alert("Merci pour ce message :)");
    },
    [message]
  );

  return (
    <Panel
      title="Un message à nous faire passer ?"
      paddingBottom
      paddingSides
    >
      <Text center>
        Une petite blague, un mot doux, une envie particulière, une allergie à
        signaler, une question, une requète...
        {pluralize(infos.pluriel, "Tu as", "Vous avez")} la parole ! Nous{" "}
        {contextualize(infos, { plural: "vous", one: "te" })}
        répondrons peut-être dès que possible :)
      </Text>
      <Spacer />
      <form css={formCss} onSubmit={onSubmit}>
        <textarea
          css={textareaCss}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Spacer />
        <Button submit label="Valider" />
      </form>
      <Spacer />
      {infos.invitation.fields.Réponse ? (
        <div css={responseCss}>
          <Text typo="minor" color="rgba(0,0,0,0.5)">
            Réponse de Val et Ben
          </Text>
          <Spacer.Free size={8} />
          <Text>{infos.invitation.fields.Réponse}</Text>
        </div>
      ) : null}
    </Panel>
  );
}
