/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { sample, without } from "lodash";
import {
  Fragment,
  useCallback,
  useEffect, useState
} from "react";
import Css from "../utilities/Css";
import Layout from "../utilities/Layout";
import useResponsive from "../utilities/useResponsive";

const PHOTOS = [
  "photos/photo_after_1.png",
  "photos/photo_after_2.png",
  "photos/photo_after_3.png",
  "photos/photo_after_4.png",
  "photos/photo_after_5.png",
  "photos/photo_after_6.png",
  "photos/photo_after_7.png",
  "photos/photo_after_8.png",
  "photos/photo_after_9.png",
  "photos/photo_after_10.png",
  "photos/photo_after_11.png",
  "photos/photo_after_12.png",
  "photos/photo_after_13.png",
  "photos/photo_after_14.png",
  "photos/photo_after_15.png",
];

export default function Photos() {
  const Component = useResponsive({
    [Layout.B]: MobilePhotos,
    bigger: DesktopPhotos,
  });

  return <Component />;
}

function MobilePhotos() {
  const [photos, selectedPhotoIndex] = usePhotoStack();

  const [onTop, setOnTop] = useState<boolean>(true);

  useEffect(() => {
    const onScroll = (e: Event) => {
      if (window.scrollY < 50) setOnTop(true);
      else setOnTop(false);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const mainCss = css`
    z-index: -1;
    position: fixed;
    top: ${onTop ? 0 : -100}px;
    left: 0px;
    right: 0px;
    width: 100vw;
    height: 100vw;
    transform: scale(${onTop ? 1 : 1.5}) translateY(${onTop ? 0 : -40}px);
    transition: top 500ms, transform 500ms;
  `;

  const photoCss = css`
    ${Css.absoluteFill};
    width: 100vw;
    height: 100vw;
    transition: opacity 500ms;
  `;

  const whiterCss = css`
    z-index: -1;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0) 66vw,
      rgba(255, 255, 255, 1) 100vw,
      rgba(255, 255, 255, 1) 100%
    );
  `;

  const whiter2Css = css`
    z-index: -1;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0) 10vw,
      rgba(255, 255, 255, 1) 50vw,
      rgba(255, 255, 255, 1) 100%
    );
    opacity: ${onTop ? 0 : 1};
    transition: opacity 500ms;
  `;


  return (
    <Fragment>
      <div css={mainCss}>
        {photos.map((p, i) => {
          const photoBackgroundCss = css`
            ${photoCss}
            opacity: ${i === selectedPhotoIndex ? 1 : 0};
          `;
          return (
            <img
              key={p}
              src={p}
              css={photoBackgroundCss}
              alt="lol"
            />
          );
        })}
      </div>
      <div css={whiterCss} />
      <div css={whiter2Css} />
    </Fragment>
  );
}

function DesktopPhotos() {
  const [photos, selectedPhotoIndex] = usePhotoStack();

  const containerCss = css`
    ${Css.absoluteFill};
    position: fixed;
    z-index: -1;
    background-color: white;
  `;

  const backgroundCss = css`
    ${Css.absoluteFill}
    background-size : cover;
    background-position: center center;
    filter: blur(30px);
    transition: opacity 500ms;
  `;

  const cardContainerCss = css`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 50%;
    ${Css.centerContent}
  `;

  const cardCss = css`
    width: 40vw;
    height: 40vw;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  `;

  const cardImage = css`
    width: 40vw;
    height: 40vw;
    transition: opacity 800ms;
    ${Css.absoluteFill}
  `;

  return (
    <div css={containerCss}>
      {photos.map((p, i) => {
        const imageCackgroundCss = css`
          ${backgroundCss}
          background-image : url(${p});
          opacity: ${i === selectedPhotoIndex ? 0.4 : 0};
        `;
        return <div key={p} css={imageCackgroundCss} />;
      })}
      <div css={cardContainerCss}>
        <div css={cardCss}>
          {photos.map((p, i) => {
            const imageCardCss = css`
              ${cardImage}
              opacity: ${i === selectedPhotoIndex ? 1 : 0};
            `;
            return (
              <img
                key={p}
                css={imageCardCss}
                src={p}
                alt=""
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

type PhotosState = { photos: Array<string>, current: number | null }

function usePhotoStack() {

  const [photos, setPhotos] = useState<PhotosState>(() => ({ photos: [], current: null }));

  const elect = useCallback((s: PhotosState, exclude: number | null) => {
    const out: PhotosState = { photos: [...s.photos], current: s.current };
    const { photos, current } = out;
    if (photos.length === 0) return s;
    if (current !== null) return out;
    let pool = Object.keys(photos).map(i => parseInt(i));
    if (exclude !== null) {
      pool = without(pool, exclude);
      if (pool.length === 0) return s;
    }
    const newSelection = sample(pool) as number
    out.current = newSelection
    return (out);
  }, [])

  useEffect(() => {
    PHOTOS.forEach(async remote => {
      const downloaded = loadedPhotos.find(photo => photo.remote === remote)
      if (downloaded) return;
      try {
        const result = await fetch(remote)
        if (!result.ok) return;
        const blob = await result.blob()
        const local = URL.createObjectURL(blob);
        setPhotos(p => {
          return elect({ photos: [...p.photos, local], current: p.current }, null)
        });
      } catch (err) {

      }
    })
  }, [])

  // useEffect(() => {
  //   if (photos.length > 0 && selected === null)
  //     electPhoto(photos, null)
  // }, [photos, selected])

  // const electPhoto = useCallback((photos: Array<string>, selection: number | null) => {
  //   if (photos.length === 0) return;
  //   let pool = Object.keys(photos).map(parseInt);
  //   if (selection !== null) {
  //     pool = without(pool, selection);
  //     if (pool.length === 0) return;
  //   }
  //   const newSelection = sample(pool) as number
  //   setSelected(newSelection);
  // }, [])

  useEffect(() => {
    setInterval(() => {
      setPhotos(p => {
        const current = p.current
        return (elect({ photos: p.photos, current: null }, current))
      })
    }, 3000)
  }, [])


  return [photos.photos, photos.current] as const;
}


const loadedPhotos: Array<{ remote: string, local: string }> = []


function usePhotos() {

  const [date, setDate] = useState(new Date())



  return (loadedPhotos.map(p => p.local));

}