import * as Sentry from "@sentry/react";
import Airtable, { Record, Records } from "airtable";
import { groupBy, sortBy } from "lodash";
import Updater from "./Updater";

Airtable.configure({ apiKey: "keyhNOb5aI70rUtDL" });
const base = new Airtable({ apiKey: "keyhNOb5aI70rUtDL" }).base(
  "appmdHUXhejOTl4LM"
);

export type RsvpValue = "Oui !" | "Non" | "Ne sait pas";

let code: string | null = null;
const inPath = window.location.pathname.replace(/[^0-9]/g, "");
if (inPath) {
  code = inPath;
  localStorage.setItem("code", code);
} else {
  const inLs = localStorage.getItem("code");
  if (inLs) code = inLs;
}

const storedLastVisit = localStorage.getItem("last_visit");
const lastVisit = storedLastVisit ? Date.parse(storedLastVisit) : null;

const last_visit = localStorage.getItem("last_visit");
localStorage.setItem("last_visit", new Date().toISOString());

const params = new URLSearchParams(window.location.search);
const silent = params.has("silent");
console.log(`Mode silencieux ${silent ? "activé" : "désactive"}`);

export type Presence = {
  famille: Record<Famille>;
  presents: Array<Record<Personne>>;
};

export type Config = {
  pluriel: boolean;
  sigulier_feminin: boolean;
  invitation: Records<Invitation>[number];
  famille: Record<Famille>;
  personnes: Records<Personne>;
  messages: Records<MessagePersonalise>;
  chats: Array<Record<Chat>>;
  tracks: Array<Record<Track>>;
  presences: Array<Presence>;
  sleepers: Array<Record<Sleeper>>;
};

type RecordId = string;

type Invitation = {
  "Code d'accès": string;
  Famille: Array<RecordId>;
  "Site visité": boolean;
  Message: string;
  Réponse: string;
  "Fichier vCard": string;
  "Dernière visite": string;
};

type Famille = {
  Libellé: string;
  Membres: Array<RecordId>;
  "Limite de tracks": number;
  Adresse: string;
};

export type Chat = {
  id: string;
  Invitation: Array<RecordId>;
  Famille: [string];
  Contenu: string;
  Date: string;
  Supprimé: boolean;
};

export type MessagePersonalise = {
  Titre: string;
  Contenu: string;
  Cible: Array<RecordId>;
  Noms: string;
};

export type Sleeper = {
  Libellé: string;
  ["Adultes cherchant un logement"]: number;
  ["Enfants cherchant un logement"]: number;
  ["Nourissons cherchant un logement"]: number;
};

export type Track = {
  Track: number;
  Titre: string;
  Album: string;
  Artiste: string;
  Cover: string;
  "Ajouté par": [string];
  Supprimé: boolean;
  "On aime": boolean;
};

export type Personne = {
  ["Prénom"]: string;
  ["Age"]: "Adulte" | "Enfant" | "Nourisson" | "Blague";
  ["Genre"]: "F" | "M" | "X";
  ["Présent l'après-midi"]: RsvpValue;
  ["Présent le soir"]: RsvpValue;
  ["Présent la nuit"]: RsvpValue;
  ["Présent le dimanche"]: RsvpValue;
  ["Cherche un logement"]: boolean;
  Famille: Array<RecordId>;
};

const updater = new Updater();

let config: { success: true; config: Config } | { success: false } | null =
  null;

async function build() {
  try {
    const invitations = await base<Invitation>("Invitations")
      .select({
        view: "Friends edition",
        filterByFormula: `{Code d'accès}=${code}`,
      })
      .all();
    const invitation = invitations[0];
    console.log("Invitation identifiée");
    const familleId = invitation.fields.Famille[0];
    const familles = await base<Famille>("Familles")
      .select({
        view: "Liste",
        filterByFormula: `RECORD_ID()="${familleId}"`,
      })
      .all();
    const famille = familles[0];
    console.log("Famille identifiée");
    Sentry.setUser({ id: famille.fields.Libellé });
    const membresIds = famille.fields.Membres;
    const personnes = await base<Personne>("Personnes")
      .select({
        filterByFormula: `OR(${membresIds
          .map((i: string) => `RECORD_ID()="${i}"`)
          .join(", ")})`,
      })
      .all();
    const sortedPersonnes = sortPersonnes(personnes);
    console.log("Personnes identifiées");

    const sigulier_feminin =
      sortedPersonnes.length === 1 && sortedPersonnes[0].fields.Genre === "F";

    config = {
      success: true,
      config: {
        pluriel: personnes.length > 1,
        sigulier_feminin,
        famille,
        invitation,
        personnes: sortedPersonnes,
        messages: [],
        chats: [],
        presences: [],
        tracks: [],
        sleepers: [],
      },
    };
    console.log("Configuration initiale faite");
    updater.update();

    await Promise.all([
      loadCustomBlocks(invitation),
      loadChat(),
      loadPresences(),
      loadPlaylist(),
      loadSleepers(),
      registerVisit(invitation),
    ]);
  } catch (err) {
    console.log("Erreur lors du chargement initial");
    Sentry.captureException(err);
    config = {
      success: false,
    };
    updater.update();
  }
}

async function loadCustomBlocks(invitation: Record<Invitation>) {
  if (!config || !config.success) return;
  try {
    const allMessages = await base<MessagePersonalise>("Messages personalisés")
      .select()
      .all();
    const messages = allMessages.filter((m) =>
      (m.fields.Cible || []).includes(invitation.id)
    );

    if (messages.length) {
      config.config.messages = messages;
      updater.update();
      console.log("Blocks persos récupérés");
    }
  } catch (err) {
    console.log("Erreur lors de la récupération des blocks persos");
    Sentry.captureException(err);
  }
}

async function loadChat() {
  if (!config || !config.success) return;
  try {
    const allChats = await base<Chat>("Chat").select().all();
    if (allChats.length) {
      config.config.chats = sortBy(
        [...allChats].filter((c) => c.fields.Supprimé !== true),
        (c) => new Date(c.fields.Date)
      );
      updater.update();
      console.log("Chat récupéré");
    }
  } catch (err) {
    console.log("Erreur lors de la récupération du chat");
    Sentry.captureException(err);
  }
}

async function loadPlaylist() {
  if (!config || !config.success) return;
  try {
    const myFamily = config.config.famille.id;
    const allTracks = await base<Track>("Tracks").select().all();
    const myTracks = allTracks
      .filter((t) => t.fields["Ajouté par"].includes(myFamily))
      .filter((t) => t.fields.Supprimé !== true);
    config.config.tracks = myTracks;
    updater.update();
  } catch (err) {
    console.log("Erreur lors de la récupération du chat");
    Sentry.captureException(err);
  }
}

async function loadPresences() {
  if (!config || !config.success) return;
  try {
    const allPersonnes = await base<Personne>("Personnes").select().all();
    const allFamilies = await base<Famille>("Familles").select().all();
    const presents = allPersonnes.filter(
      (p) =>
        p.fields["Présent l'après-midi"] === "Oui !" ||
        p.fields["Présent le soir"] === "Oui !"
    );
    const groups = groupBy(presents, (p) => p.fields["Famille"][0]);
    const presences: Array<Presence> = Object.entries(groups).map(
      ([idFamille, membres]) => {
        const famille = allFamilies.find((f) => f.id == idFamille);
        if (!famille) throw new Error("No family");
        return { famille, presents: sortPersonnes(membres) };
      }
    );

    config.config.presences = presences;
    updater.update();
    console.log("Présences récupérées");
  } catch (err) {
    console.log("Erreur lors de la récupération des presences");
    Sentry.captureException(err);
  }
}

async function loadSleepers() {
  if (!config || !config.success) return;
  try {
    const sleepers = await base<Sleeper>("Familles")
      .select({ view: "Hébergement" })
      .all();
    config.config.sleepers = [...sleepers];
    updater.update();
  } catch (err) {
    console.log("Erreur lors de la récupération des presences");
    Sentry.captureException(err);
  }
}

async function registerVisit(invitation: Record<Invitation>) {
  try {
    if (!silent) {
      updateInvitationValue(
        invitation.id,
        "Dernière visite",
        new Date().toISOString()
      );
      console.log("Visite initiale trackée");
    }
  } catch (err) {
    console.log("Erreur lors du tracking de la visite");
    Sentry.captureException(err);
  }
}

async function updatePersonneValue<TField extends keyof Personne>(
  id: string,
  field: TField,
  value: Personne[TField]
) {
  const newP = await base<Personne>("Personnes").update(id, { [field]: value });
  if (config && config.success) {
    config.config.personnes = config.config.personnes.map((p) =>
      p.id === newP.id ? newP : p
    );
    updater.update();
  }
}

async function updateInvitationValue<TField extends keyof Invitation>(
  id: string,
  field: TField,
  value: Invitation[TField]
) {
  await base<Invitation>("Invitations").update(id, { [field]: value });
}

async function updateInvitation<TField extends keyof Invitation>(
  id: string,
  updates: Partial<Invitation>
) {
  const invitation = await base<Invitation>("Invitations").update(id, updates);
  if (config && config.success) {
    config.config.invitation = invitation;
    updater.update();
  }
}

async function updateFamilleValue<TField extends keyof Famille>(
  id: string,
  field: TField,
  value: Famille[TField]
) {
  console.log({ [field]: value });
  await base<Famille>("Familles").update(id, { [field]: value });
}

async function addChat(invitation: Record<Invitation>, message: string) {
  const newMessage = await base<Chat>("Chat").create({
    id: Math.random().toString(),
    Contenu: message,
    Invitation: [invitation.id],
    Date: new Date().toISOString(),
  });
  if (config && config.success) {
    config.config.chats.push(newMessage);
    updater.update();
  }
}

async function editChat(id: RecordId, message: string) {
  const newMessage = await base<Chat>("Chat").update(id, { Contenu: message });

  if (config && config.success) {
    config.config.chats = config.config.chats.map((c) =>
      c.id === id ? newMessage : c
    );
    updater.update();
  }
}

async function deleteChat(id: RecordId) {
  await base<Chat>("Chat").update(id, { Supprimé: true });
  if (config && config.success) {
    config.config.chats = config.config.chats.filter((c) =>
      c.id === id ? false : true
    );
    updater.update();
  }
}

function useLoading() {
  return updater.useValue(() => {
    return config ? config.success : null;
  });
}

function useInfos() {
  return updater.useValue(() => {
    if (!config) throw new Error("No config...");
    if (!config.success) throw new Error("Error...");
    return config.config;
  });
}

function sortPersonnes(personnes: Array<Record<Personne>> | Records<Personne>) {
  return sortBy(
    personnes,
    (p) => {
      if (p.fields.Age === "Adulte") return 1;
      else if (p.fields.Age === "Enfant") return 2;
      else if (p.fields.Age === "Nourisson") return 3;
      else return 4;
    },
    (p) => {
      if (p.fields.Genre === "F") return 1;
      else if (p.fields.Genre === "M") return 2;
      else return 3;
    }
  );
}

async function addTrack(track: DeezerTrack) {
  if (config && config.success) {
    const record = await base<Track>("Tracks").create({
      Track: track.id,
      Titre: track.title,
      Album: track.album.title,
      Cover: track.album.cover_medium,
      Artiste: track.artist.name,
      "Ajouté par": [config.config.famille.id],
    });
    config.config.tracks = [...config.config.tracks, record];
    updater.update();
  }
}

async function removeTrack(track: Record<Track>) {
  if (config && config.success) {
    await base<Track>("Tracks").update(track.id, { Supprimé: true });
    config.config.tracks = config.config.tracks.filter(
      (t) => t.id !== track.id
    );
    updater.update();
  }
}

build();

function getCode() {
  return code;
}

const Data = {
  useLoading,
  useInfos,
  addChat,
  editChat,
  deleteChat,
  updatePersonneValue,
  updateInvitationValue,
  updateInvitation,
  addTrack,
  removeTrack,
  isNew,
  loadSleepers,
  getCode,
  updateFamilleValue,
};

function isNew(contentDate: string | undefined) {
  return typeof contentDate === "string";
  // if (!contentDate) return false;
  // if (!lastVisit) return false;
  // const contentDateTs = Date.parse(contentDate).valueOf();
  // return contentDateTs >= lastVisit;
}

export default Data;

export type DeezerTrack = {
  id: number;
  title: string;
  album: { title: string; cover_medium: string };
  artist: { name: string };
};
