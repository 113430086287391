/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Record } from "airtable";
import { format, isBefore } from "date-fns";
import fr from "date-fns/locale/fr";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import SyncLoader from "react-spinners/SyncLoader";
import "reset-css";
import Button from "../components/Button";
import Content from "../components/Content";
import Divider from "../components/Divider";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import contextualize from "../utilities/contextualize";
import Css from "../utilities/Css";
import "../utilities/Data";
import Data, { DeezerTrack, Track } from "../utilities/Data";
import Layout from "../utilities/Layout";
import Typo from "../utilities/Typo";

const base = process.env.REACT_APP_DEEZER_ENDPOINT
const trackHeight = 60;
const playerHeight = 400;

const closing = new Date("2022-09-08T20:00:00Z")
const isEditable = isBefore(new Date(), closing);

export default function Playlist() {
  const infos = Data.useInfos();

  const code = Data.getCode();

  const [playerKey, setPlayerKey] = useState<number | null>(Date.now());
  const [placeholder, setPlaceholder] = useState<string | null>(null);
  const [searching, setSearching] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [suggestions, setSuggestions] = useState<Array<DeezerTrack> | null>(
    null
  );

  const performSearch = useCallback(async (search: string) => {
    const res = await fetch(`${base}?action=search&search=${search}`, {
      method: "post",
    });
    const data = await res.json();
    setSuggestions(data);
  }, []);

  const trackList = useMemo(() => [...infos.tracks].reverse(), [infos.tracks]);

  const addTrack = useCallback(async (track: DeezerTrack) => {
    const limit = infos.famille.fields["Limite de tracks"] as number | null;
    if (typeof limit === "number" && trackList.length > limit) {
      alert(`Merci pour cet engouement ! Pour le bien des oreilles de chacun, il est préféable que ${contextualize(infos, { plural: "vous vous limitiez", one: "tu te limites" })} à ${limit} morceaux... :) `)
      setSearch("");
      return;
    }
    setPlaceholder(
      `"${track.title}"... Super idée ! On l'ajoute à la playlist...`
    );
    setPlayerKey(null);
    setSearch("");
    setSuggestions(null);
    try {
      const res = await fetch(`${base}?action=addTrack&track=${track.id}`, {
        method: "post",
      });
      if (!res.ok) {
        if (res.status === 409) {
          inform(
            `"${track.title}" a déja été ajouté dans la playlist par quelqu'un d'autre :/`
          );
          setPlaceholder(null);
        } else {
          inform(`Ca n'a pas fonctionné :/`);
          setPlaceholder(null);
        }
      } else {
        inform(`Et voila, un morceau de plus ! Merci :)`);
        setPlaceholder(null);
        Data.addTrack(track);
        loadDuration();
      }
      setPlayerKey(Date.now());
    } catch (err) {
      inform(`Ca n'a pas fonctionné :/`);
      setPlaceholder(null);
    }

  }, [trackList]);

  const onRemoveTrack = useCallback(async (track: Record<Track>) => {
    setPlaceholder(`Retirons ce morceau...`);
    try {
      const res = await fetch(`${base}?action=removeTrack&track=${track.fields.Track}`, {
        method: "post",
      });
      if (!res.ok) {
        setPlaceholder(null);
        inform(`Ca n'a pas fonctionné :/`);
      } else {
        Data.removeTrack(track);
        setPlayerKey(null);
        loadDuration();
        setPlayerKey(Date.now());
        setPlaceholder(null)
        inform("Retiré !");
      }
    } catch (err) {
      inform(`Ca n'a pas fonctionné :/`);
      setPlaceholder(null);
    }
  }, []);

  const shufflePlaylist = useCallback(async () => {
    try {
      const res = await fetch(`${base}?action=shuffle`);
      if (!res.ok) {
        inform(`Ca n'a pas fonctionné :/`);
      } else {
        setPlayerKey(null);
        inform(`C'est ok :)'`);
        setTimeout(() => {
          setPlayerKey(Date.now());
        }, 0);
      }
    } catch (err) {
      inform(`Ca n'a pas fonctionné :/`);
    }
  }, []);

  const inform = useCallback((p: string) => {
    alert(p);
  }, []);

  const trySomethingElse = useCallback(() => {
    setSearch("");
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!search) setSuggestions(null);
  }, [search]);

  useEffect(() => {
    if (debouncedSearch === "") return;
    performSearch(debouncedSearch);
  }, [debouncedSearch]);

  const searchZoneCss = css`
    width: 100%;
    transition : background 1000ms ;
    padding : 3px ;
    background : black ;
    position : relative ;
    overflow : visible ;
    z-index :2 ;
  `;

  const searchZoneFiller = css`
  ${Css.absoluteFill} ;
  top : -5px ;
  left : -5px ;
  right : -5px ;
  bottom : -5px ;
  background : linear-gradient(to right, rgb(255, 30, 140) 10%, rgb(255, 102, 57), rgb(255, 230, 29) 40%, rgb(255, 230, 29), rgb(255, 230, 29) 60%, rgb(87, 229, 98), rgb(31, 179, 253) 85%, rgb(31, 179, 253), rgb(31, 179, 253)) ;
  opacity : ${searching ? 1 : 0} ;
  transition : 200ms ;
  z-index : 1 ;
  filter : blur(10px) ;
  `

  const searchCss = css`
  position : relative ;
  z-index : 2 ;
    ${Css.buttonReset};
    ${Typo.body};
    width: 100%;
    padding: ${Layout.P}px;
    background-color: black ;
    background-image: url(loupe.png) ;
    background-size : 24px ;
    background-position : 16px center ;
    background-repeat : no-repeat ;
    color: white;
    text-align: left;
    border-radius: 0px;
    padding-left : 60px ;
    transition: background-color 200ms;
    &:focus {
      outline: none;
      background-color: rgb(0, 0, 0);
    }
  `;

  const suggestionsCss = css`
    ${Css.absoluteFill}
    z-index : 1;
    background-color: rgb(25, 25, 25);
    color: white;
    height: 100%;
    overflow: auto;
  `;

  const suggestionsFillerCss = css`
    ${Css.absoluteFill}
    z-index : 1;
    background-color: rgb(25, 25, 25);
    color: white;
    height: 100%;
    overflow: auto;
    ${Css.centerContent}
    flex-direction : column;
  `;

  const switchingContentCss = css`
    position: relative;
    z-index : 1 ;
    width: 100%;
    height: ${playerHeight}px;
  `;

  let suggestionsNode: ReactNode = null;
  if (search) {
    if (suggestions === null) {
      suggestionsNode = (
        <div css={suggestionsFillerCss}>
          <SyncLoader color="white" />
        </div>
      );
    } else if (suggestions.length === 0) {
      suggestionsNode = (
        <div css={suggestionsFillerCss}>
          Aucun résultat !<Spacer />
          <Button label="Essayer autre chose" onClick={trySomethingElse} />
        </div>
      );
    } else {
      suggestionsNode = (
        <div css={suggestionsCss}>
          {suggestions.map((s) => (
            <TrackSelector key={s.id} track={s} onSelect={addTrack} />
          ))}
        </div>
      );
    }
  }

  const backgroundCss = css`
    ${Css.absoluteFill}
    ${Css.centerContent};
    color: white;
    z-index: 0;
    background-color: rgb(25, 25, 25);
  `;

  const playerCss = css`
    ${Css.absoluteFill};

    z-index: 1;
  `;

  const tracksCss = css`
    display: flex;
    padding: ${Layout.P}px;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
  `;

  const backgroundNode = (
    <div css={backgroundCss}>
      <SyncLoader color="white" />
    </div>
  );

  let tracksText: string;
  if (infos.tracks.length === 0)
    tracksText =
      tracksText = `Si chacun choisit 5 morceaux, on devrait avoir du son toute la journée ! A ${contextualize(
        infos,
        {
          plural: "vous",
          one: "toi",
        }
      )} de jouer !`;
  else if (infos.tracks.length === 1)
    tracksText = `Merci ! C'est un excellent premier moreceau !`;
  else if (infos.tracks.length === 2)
    tracksText = `${contextualize(infos, {
      plural: "Variez",
      one: "Varie",
    })} les styles, les artistes... ! 3 morceaux de plus et le tour est joué !`;
  else if (infos.tracks.length === 3)
    tracksText = `${contextualize(infos, {
      plural: "Vous vous approchez du but, plus que 2 morceaux à choisir !",
      one: "Tu t'approches du but !",
    })}`;
  else if (infos.tracks.length === 4)
    tracksText = tracksText = `${contextualize(infos, {
      plural: "Vous y êtes",
      one: "Tu y es",
    })} presque ! Un petit dernier ? `;
  else if (infos.tracks.length === 5)
    tracksText = `Bien joué ! ${contextualize(infos, {
      plural: "N'hésitez pas",
      one: "N'hésite pas",
    })} à ajouter d'autres morceaux !`;
  else
    tracksText = `${contextualize(infos, {
      plural: "Faites-vous",
      one: "Fais-toi",
    })} plaisir !`;

  const [duration, setDuration] = useState<number | null>(null)
  const wanted = 11 * 60 * 60
  const left = wanted - (duration || 0);

  const loadDuration = useCallback(async () => {
    const res = await fetch(`${base}?action=duration`);
    if (res.ok) {
      const data = await res.json();
      setDuration(data.duration);
    } else {
      console.log()
    }
  }, [])

  useEffect(() => {
    loadDuration();
  }, [])

  const jaugeCss = css`
    height : 20px ;
    box-shadow : 0px 0px 6px rgba(0,0,0,0.4) ;
    margin : 20px ;
    margin-top : 0px ;
    border-radius : 10px ;
    align-self : stretch ;
    background: linear-gradient(to right, #ff0080, #ff8c00,#40e0d0 );
    display : flex ;
    justify-content : flex-end ;
  `

  const thumbCss = css`
    width : ${100 - (((duration || 0) / wanted) * 100)}% ;
    border-top-right-radius : 10px ;
    border-bottom-right-radius : 10px ;
    background-color : white ;
    opacity : 0.8 ;
    transition : width 200ms ;
  `

  console.log("Duration", duration);

  return (
    <Panel title="🎶 Spin that record, babe ! 🎶" lastUpdate="lol">
      {isEditable ?
        <Content>
          <Text center typo="minor"> La musique, oui, la musique</Text>
          <Text center typo="minor">Je le sais, sera la clé</Text>
          <Text center typo="minor">De l'amour, de l'amitié</Text>
          <Text center typo="minor" color="rgba(0,0,0,0.5)">Nicoletta</Text>
          <Spacer.Half />
          <Text center>
            Parce qu'il y aura autant de goûts musicaux que de personnes présentes, nous  {contextualize(infos, { plural: "vous proposons", one: "te proposons" })} la construction d'une playlist collaborative.
            Chacun peut ajouter les sons qu'il veut... et la playlist sera filtrée, triée et lue le jour J :)
          </Text>
          <Spacer.Half />
          <Text center>
            {contextualize(infos, { plural: "Envoyez vos", one: "Envoie tes" })} sons 🤩,  {contextualize(infos, { plural: "vos", one: "tes" })} coups de 💖, vos ✨ du moment, et tous  {contextualize(infos, { plural: "vos", one: "tes" })} morceaux 🎉 🏳️‍🌈 🥁 🥳 🔥 !
          </Text>
          <Spacer />
          <Text center typo="subheading" color="#CC0000">Derniers instants ! La playlist fermera ses portes ce {format(new Date(closing), "iiii d MMMM 'à' H:mm", { locale: fr })}</Text>

        </Content> : null}
      {isEditable ? <Fragment>
        <Spacer />
        <Button
          onClick={() => (inputRef.current ? inputRef.current.focus() : null)}
          label={"Rechercher un morceau"}
        />
        <Spacer /></Fragment> : null}
      {isEditable ?
        <div css={searchZoneCss}>
          <div css={searchZoneFiller} />
          <input
            ref={inputRef}
            css={searchCss}
            type="text"
            value={search}
            onFocus={() => setSearching(true)}
            onBlur={() => setSearching(false)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={
              placeholder ||
              "Artiste, album, morceau..."
            }
          />
        </div> : null}
      <div css={switchingContentCss}>
        {backgroundNode}
        <div css={playerCss}>
          {playerKey ? (
            <iframe
              key={playerKey}
              style={{ display: "block" }}
              id="deezer-widget"
              src="https://widget.deezer.com/widget/dark/playlist/9911415442?app_id=457142&autoplay=true&radius=false&tracklist=true"
              width="100%"
              height={`${playerHeight}px`}
              allowFullScreen={true}
              allow="encrypted-media"
              title="Deezer"
            ></iframe>
          ) : null}
        </div>
        {suggestionsNode}
      </div>
      {isEditable ? <Fragment>
        <Spacer />
        <Content>
          <Text center>{`Voici les morceaux que ${contextualize(infos, {
            plural: "vous avez",
            one: "tu as",
          })} choisi`}</Text>
          <Text center>{tracksText}</Text>
        </Content>
        <div css={tracksCss}>
          {trackList.map((t) => (
            <UserTrack track={t} key={t.id} onRemove={onRemoveTrack} />
          ))}
        </div>
        {
          duration !== null && left > 0 ? <Fragment>
            <Text typo="minor">Il nous faut encore {formatDuration(left)} de son !</Text>
            <Spacer.Half />
            <div css={jaugeCss}><div css={thumbCss}></div>
            </div>
            <Button
              onClick={() => (inputRef.current ? inputRef.current.focus() : null)}
              label={"Rechercher un morceau"}
            />
            <Spacer />
          </Fragment> : null
        }

        {
          code === "3272" ?
            <Fragment><Button
              onClick={shufflePlaylist}
              label={"Mélanger la playlist"}
            /><Spacer /></Fragment> : null
        }
      </Fragment> : null}


    </Panel>
  );
}

function formatDuration(sec: number) {
  const hours = Math.floor(sec / 3600);
  const secLeft = sec - hours * 3600;
  const min = Math.floor(secLeft / 60);
  if (hours) {
    if (min) {
      return (hours + " heures et " + min + " minutes");
    } else {
      return (hours + " heures");
    }
  } else {
    return min + " minutes"
  }

}

type TrackSelectorProps = {
  track: DeezerTrack;
  onSelect: (track: DeezerTrack) => any;
};

function TrackSelector(props: TrackSelectorProps) {
  const { track, onSelect } = props;

  const containerCss = css`
    display: flex;
    height: ${trackHeight}px;
    padding: 2px;
    align-items: center;
    min-width: 0px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    transition: background-color 200ms 50ms;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      transition: background-color 50ms;
    }
  `;

  const coverCss = css`
    height: ${trackHeight - 16}px;
    width: ${trackHeight - 16}px;
    border-radius: 4px;
  `;

  const textsCss = css`
    flex-shrink: 1;
    min-width: 0px;
  `;

  const onClick = useCallback(() => {
    onSelect(track);
  }, [track]);

  return (
    <div css={containerCss} onClick={onClick}>
      <Spacer.Thin />
      <img css={coverCss} src={track.album.cover_medium} />
      <Spacer.Thin />
      <div css={textsCss}>
        <Text singleLine>{track.title}</Text>
        <Text
          typo="minor"
          singleLine
        >{`${track.artist.name} - ${track.album.title}`}</Text>
      </div>
      <Spacer.Thin />
    </div>
  );
}

function useDebounce(value: string, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

type UserTrackProps = {
  track: Record<Track>;
  onRemove: (track: Record<Track>) => void;
};

function UserTrack(props: UserTrackProps) {
  const track = props.track || ({ fields: {} } as Record<Track>);
  const bg = track.fields.Cover || "";
  const cover = track.fields.Cover || null;
  const titre = track.fields.Titre || "";
  const author = track.fields.Artiste || "";
  const album = track.fields.Album || "";

  const containerCss = css`
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;

    align-items: center;
    min-width: 0px;
    width: 180px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transition: background-color 200ms 50ms;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      transition: background-color 50ms;
    }
  `;

  const coverCss = css`
    height: 160px;
    width: 160px;
    border-radius: 4px;
    display: block;
    margin-top: 10px;
  `;

  const textsCss = css`
    flex-shrink: 1;
    width: 100%;
    min-width: 0px;
  `;

  const bgCss = css`
    ${Css.absoluteFill}
    background-image : url(${bg});
    background-size: cover;
    z-index: 0;
    ${props.track
      ? `filter : blur(10px) ; transform : scale(1.2) ; opacity : 0.5;`
      : undefined}
  `;

  const contentCss = css`
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  `;

  const wrapperCss = css`
    position: relative;
    margin: ${Layout.P / 2}px;
  `;

  const deleteCss = css`
    ${Css.buttonReset}
    ${Css.centerContent}
    font-size : 24px;
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    z-index: 2;
    
  `;

  const loveCss = css`
    ${Css.centerContent}
    font-size : 14px;
    color: black ;
    position: absolute;
    top: 40px ;
    left: 60px ;
    width: 60px;
    height: 60px;
    background-color: darkred ;
    border-radius: 30px;
    z-index: 2;
    box-shadow : 0px 0px 20px darkred ;
    border : 4px solid rgba(255,255,255,0.5) ;
    text-align : center ;
    display : flex ;
    align-items : center ;
    justify-content : center ;

    & > *{
      transition : opacity 200ms ;
    }

    &:hover{
      animation: heartbeat 1.8s infinite;
      & > *{
        opacity : 1 ;
        transition : opacity 200ms ;
        
      }
    }

    @keyframes heartbeat {
      0% {
        transform: scale( 1);
      }
      10% {
        transform: scale( 1.2);
      }
      20% {
        transform: scale( 1);
      }
      30% {
        transform: scale( 1.2);
      }
      40% {
        transform: scale( 1);
      }
      50% {
        transform: scale( 1);
      }
      100% {
        transform: scale( 1);
      }
    }

  `;

  const weLoveCss = css`
    position : absolute ;
    bottom : 0px ;
    left : 50% ;
    transform : translate3d(-50%, 80%, 0px) ;
    white-space : nowrap ;
    text-align : center ;
    background-color : white ;
    padding : 5px 10px ;
    border-radius : 10px ;
    color : darkred ;
    box-shadow : 0px 0px 20px darkred ;
    opacity : 0 ;
    transition : opacity 500ms ;
  `

  const onRemove = useCallback(() => {
    props.onRemove(props.track);
  }, [props.onRemove, props.track]);

  return (
    <div css={wrapperCss}>
      {track.fields["On aime"] ?
        <div css={loveCss}>
          <svg xmlns="http://www.w3.org/2000/svg" overflow="visible" width="20" height="20" stroke="white" opacity={0.6} strokeWidth={2} fill="white" viewBox="0 0 16 16"> <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" /> </svg>
          <div css={weLoveCss}><Text>On aime !</Text></div>
        </div>
        : <button type="button" onClick={onRemove} css={deleteCss}>
          ×
        </button>}
      <div css={containerCss}>
        <div css={bgCss} />
        <div css={contentCss}>
          {cover ? <img css={coverCss} src={cover} /> : null}
          <Spacer.Half />

          <div css={textsCss}>
            <Text center typo="minor" singleLine>
              {titre}
            </Text>
            <Spacer.Thin />
            <Divider />
            <Spacer.Thin />
            <Text center typo="minor" singleLine color="rgba(0,0,0,0.6)">
              {author}
            </Text>
            <Text center typo="minor" singleLine color="rgba(0,0,0,0.6)">
              {album}
            </Text>
            <Spacer.Half />
          </div>
        </div>
      </div>
    </div>
  );
}
