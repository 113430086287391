/** @jsxImportSource @emotion/react */
import "reset-css";
import Button from "../components/Button";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import contextualize from "../utilities/contextualize";
import Data from "../utilities/Data";

export default function Cash() {

  const infos = Data.useInfos();

  return (
    <Panel
      title="Cagnotte !"
      paddingBottom
      paddingSides
      lastUpdate={"Oui"}
    >
      <Text center>
        Réclamée par beaucoup d'entre vous, la voici enfin ! 🤣</Text><Spacer />
      <Text center>
        On {contextualize(infos, { plural: "vous", one: "te" })} remercie par avance ! On pensera fort à {contextualize(infos, { plural: "vous", one: "toi" })} quand on foulera le sol des Seychelles !
      </Text>
      <Spacer />
      <Button href="https://lydia-app.com/collect/87600-mariage-val-ben/re" target="_blank" label="Participer" />
    </Panel>
  );
}
