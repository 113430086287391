/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import Typo, { TypoName } from "../utilities/Typo";

type TextProps = {
  typo?: TypoName;
  center?: boolean;
  component?: "label" | "p";
  html?: boolean;
  children: string | Array<string>;
  color?: string;
  singleLine?: boolean;
};

export default function Text(props: TextProps) {
  const typo = props.typo || "body";
  const textAlign = props.center ? "center" : "left";
  const component = props.component || "p";
  const singleLine = props.singleLine || false;

  const textCss = css`
    ${Typo[typo]}
    text-align : ${textAlign};
    color: ${props.color || "inherit"};
    ${singleLine ? "white-space: nowrap ;" : ""}
    ${singleLine ? "text-overflow: ellipsis ;" : ""}
    ${singleLine ? "overflow: hidden ;" : ""}
  `;

  const children = props.children || "\u00A0";
  const str = Array.isArray(children) ? children.join(" ") : children;

  const content = props.html
    ? { dangerouslySetInnerHTML: { __html: str } }
    : { children: str };

  if (component === "label") {
    return <label css={textCss} {...content} />;
  } else {
    return <p css={textCss} {...content} />;
  }
}
