import { Config } from "./Data";

type Contextualization = {
  plural: string;
  one: string;
  woman?: string;
};

export default function contextualize(
  config: Config,
  contextualization: Contextualization
) {
  if (config.pluriel) return contextualization.plural;
  else if ("woman" in contextualization && config.sigulier_feminin)
    return contextualization.woman as string;
  else return contextualization.one;
}
