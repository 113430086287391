/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from "@emotion/react";
import { PropsWithChildren } from "react";
import Data from "../utilities/Data";
import Layout from "../utilities/Layout";
import useResponsive from "../utilities/useResponsive";
import Spacer from "./Spacer";
import Text from "./Text";

type PanelProps = PropsWithChildren<{
  title: string;
  paddingBottom?: boolean;
  paddingSides?: boolean;
  containerCss?: SerializedStyles;
  lastUpdate?: string;
}>;

export default function Panel(props: PanelProps) {
  const Component = useResponsive({
    [Layout.B]: MobilePanel,
    bigger: DesktopPanel,
  });

  return <Component {...props} />;
}

function MobilePanel(props: PanelProps) {
  const containerCss = css`
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: ${Layout.S}px;
    padding-bottom: ${props.paddingBottom ? Layout.S : 0}px;
    padding-left: ${props.paddingSides ? Layout.S : 0}px;
    padding-right: ${props.paddingSides ? Layout.S : 0}px;
    border-radius: 4px;
    margin-bottom: ${Layout.S * 2}px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    ${props.containerCss}
  `;

  const newCss = css`
    position: absolute;
    top: -16px;
    right: -16px;
    width: 150px;
    height: 150px;
    background-image: url(new.png);
  `;

  const isNew = Data.isNew(props.lastUpdate);

  return (
    <div css={containerCss}>
      {isNew ? <div css={newCss} /> : null}
      <Text typo="heading" center>
        {props.title}
      </Text>
      <Spacer />
      {props.children}
    </div>
  );
}

function DesktopPanel(props: PanelProps) {
  const containerCss = css`
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: ${Layout.S}px;
    padding-bottom: ${props.paddingBottom ? Layout.S : 0}px;
    padding-left: ${props.paddingSides ? Layout.S : 0}px;
    padding-right: ${props.paddingSides ? Layout.S : 0}px;
    border-radius: 4px;
    margin-bottom: ${Layout.S * 2}px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
    align-items: center;
    position: relative;
    ${props.containerCss}
  `;

  const newCss = css`
    position: absolute;
    top: -16px;
    right: -16px;
    width: 150px;
    height: 150px;
    background-image: url(new.png);
  `;

  const isNew = Data.isNew(props.lastUpdate);

  return (
    <div css={containerCss}>
      {isNew ? <div css={newCss} /> : null}
      <Text typo="heading" center>
        {props.title}
      </Text>
      <Spacer />
      {props.children}
    </div>
  );
}
