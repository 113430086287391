/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { difference, max, random, without } from "lodash";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Panel from "../components/Panel";
import Text from "../components/Text";
import contextualize from "../utilities/contextualize";
import Css from "../utilities/Css";
import "../utilities/Data";
import Data, { Presence } from "../utilities/Data";
import useConcat from "../utilities/useConcat";

const displays: Record<string, string> = {};

export default function Presents() {
  const infos = Data.useInfos();

  return (
    <Panel
      title={`Ils seront présents !`}
      paddingBottom
      paddingSides
    >
      <CarouselPresence id="one" />
      <CarouselPresence id="two" />
    </Panel>
  );
}

type CarouselPresenceProps = {
  id: string;
};
type Rect = { width: number; height: number };

function CarouselPresence(props: CarouselPresenceProps) {
  const { id } = props;
  const infos = Data.useInfos();

  const presences = infos.presences;

  const [rects, setRects] = useState<Record<string, Rect>>({});

  const onLayout = useCallback((famille: string, rect: Rect) => {
    setRects((current) => ({ ...current, [famille]: rect }));
  }, []);

  const [rect, setRect] = useState<Rect | null>(null);

  useEffect(() => {
    if (
      Object.entries(rects).length === presences.length &&
      presences.length !== 0
    ) {
      setRect({
        width: max(Object.values(rects).map((r) => r.width)) || 0,
        height: max(Object.values(rects).map((r) => r.height)) || 0,
      });
    }
  }, [rects, presences]);

  const [visible, setVisible] = useState<string | null>(null);

  const elect = useCallback(() => {
    setVisible((current) => {
      let pool = presences.map((p) => p.famille.id);
      pool = without(pool, current || "");
      pool = difference(pool, Object.values(displays));
      const elected = pool[random(0, pool.length - 1)];
      displays[id] = elected;
      return elected;
    });
    setTimeout(elect, random(4000, 7000));
  }, [id, presences]);

  useEffect(() => {
    if (!rect) return;
    elect();
  }, [rect, presences]);

  const containerCss = rect
    ? css`
        width: ${rect.width}px;
        height: ${rect.height}px;
        position: relative;
      `
    : css`
        position: relative;
      `;

  return (
    <div css={containerCss}>
      {presences.map((p) => {
        return (
          <PresenceSlide
            absolute={!!rect}
            visible={visible === p.famille.id}
            key={p.famille.id}
            presence={p}
            onLayout={onLayout}
          />
        );
      })}
    </div>
  );
}

type PresenceSlideProps = {
  absolute: boolean;
  visible: boolean;
  presence: Presence;
  onLayout: (famille: string, rect: Rect) => any;
};

function PresenceSlide(props: PresenceSlideProps) {
  const { visible, absolute, presence, onLayout } = props;
  const infos = Data.useInfos();

  const ref = useRef<HTMLDivElement>(null);

  const children = useMemo(() => {
    return presence.presents.filter((p) => p.fields.Age !== "Adulte");
  }, [presence]);

  const names = useConcat(
    children.map((p) => p.fields["Prénom"]),
    ", ",
    "et"
  );

  const containerCss = css`
    ${absolute ? Css.absoluteFill : ""}
    ${Css.centerContent}
    opacity : ${visible ? 1 : 0};
    transition: opacity 200ms;
    flex-direction: column;
    padding: 10px;
  `;

  useEffect(() => {
    if (!ref.current) return;
    onLayout(presence.famille.id, {
      width: ref.current.clientWidth + 10,
      height: ref.current.clientHeight + 10,
    });
  }, [presence]);

  const mainText =
    infos.famille.id === presence.famille.id
      ? contextualize(infos, { plural: "🤩 Vous 🤩", one: "🤩 Toi 🤩" })
      : presence.famille.fields["Libellé"];

  return (
    <div ref={ref} key={presence.famille.id} css={containerCss}>
      <Text typo="subheading" center>
        {mainText}
      </Text>
      {names ? (
        <Text typo="minor" center>
          {`avec ${names}`}
        </Text>
      ) : null}
    </div>
  );
}
