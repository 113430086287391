/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import Layout from "../utilities/Layout";
import useResponsive from "../utilities/useResponsive";

type ContainerProps = PropsWithChildren<{}>;

export default function Container(props: ContainerProps) {
  const Component = useResponsive({
    [Layout.B]: MobileContainer,
    bigger: DesktopContainer,
  });

  return <Component {...props} />;
}

function MobileContainer(props: ContainerProps) {
  const containerCss = css`
    padding-top: 50vw;
  `;

  return <div css={containerCss}>{props.children}</div>;
}

function DesktopContainer(props: ContainerProps) {
  const containerCss = css`
    padding-left: calc(50vw + ${Layout.S * 2}px);
    padding-right: 7vw;
  `;

  return <div css={containerCss}>{props.children}</div>;
}
