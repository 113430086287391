/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useCallback, useState } from "react";
import "reset-css";
import Divider from "../components/Divider";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import Css from "../utilities/Css";
import "../utilities/Data";
import Data, { RsvpValue } from "../utilities/Data";
import pluralize from "../utilities/pluralize";

export default function Rsvp() {
  const infos = Data.useInfos();

  const onRsvp = useCallback((key: string, field: any, value: string) => {
    Data.updatePersonneValue(key, field, value);
  }, []);

  const controlsCss = css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  `;
  const controlCss = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width : 150px ;
    outline : 5px solid currentColor ;
    margin : 10px ;
    border-radius : 4px ;
    color : white ;
    background-image : linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
  `;

  return (
    <Panel
      title={`${pluralize(
        infos.pluriel,
        "Seras-tu",
        "Serez-vous"
      )} de la partie ?`}
      paddingBottom
      paddingSides
    >
      {infos.personnes.map((r) => {
        if (r.fields.Age === "Blague") return null;
        const am = r.fields["Présent l'après-midi"]
        const soir = r.fields["Présent le soir"]
        const amColor = am === "Oui !" ? "green" : am === "Ne sait pas" ? "orange" : "darkred"
        const soirColor = soir === "Oui !" ? "green" : soir === "Ne sait pas" ? "orange" : "darkred"
        return (
          <Fragment key={r.id}>
            <Spacer />
            <Text center typo="subheading">
              {r.fields["Prénom"]}
            </Text>
            <div css={controlsCss}>
              <div css={css`${controlCss} ; background-color : ${amColor}`}>
                <Text center>Après-midi</Text>
                <Spacer.Half />
                <Text center typo="subheading">{r.fields["Présent l'après-midi"]}</Text>
              </div>
              <div css={css`${controlCss} ; background-color : ${soirColor}`}>
                <Text center>Soirée</Text>
                <Spacer.Half />
                <Text center typo="subheading">{r.fields["Présent le soir"]}</Text>
              </div>
            </div>
            <Spacer />
            <Divider />
          </Fragment>
        );
      })}
      <Spacer />
      <Text center>
        Un changement de programme ? {" "}
        {pluralize(infos.pluriel, "Contacte-nous", "Contactez-nous")} par texto ou par Messenger pour nous en informer !
      </Text>

    </Panel>
  );
}

type RsvpSelectorProps = {
  value: RsvpValue;
  onChange: (value: RsvpValue) => any;
};

function byRsvpValue<TOut>(
  value: RsvpValue,
  ifYes: TOut,
  ifDontKnow: TOut,
  ifNo: TOut
): TOut {
  if (value === "Oui !") return ifYes;
  else if (value === "Non") return ifNo;
  else return ifDontKnow;
}

function RsvpSelector(props: RsvpSelectorProps) {
  const [value, setValue] = useState<RsvpValue>(props.value);

  const bgCss = css`
    ${Css.absoluteFill};
    transition: opacity 200ms;
    z-index: 0
    overflow: hidden;
    border-radius : 4px ;
    background-color : ${value === "Oui !"
      ? "green"
      : value === "Ne sait pas"
        ? "orange"
        : "darkred"
    } ;
    background-image : linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
  `;

  const container = css`
    ${Css.buttonReset}
    height: 50px;
    align-items: center;
    position: relative;
  `;

  const partsCss = css`
    display: flex;
    position: relative;
    z-index: 1;
    color: white;
  `;

  const partCss = css`
    ${Css.buttonReset}
    flex-shrink: 0;
    width: 70px;
    white-space: nowrap;
    padding: 3px;
    height: 50px;
    transition: transform 100ms;
    &:hover {
      transform: scale(1.1);
    }
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `;

  const left = byRsvpValue(value, 0, 70, 140);

  const spotCss = css`
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: ${left}px;
    width: 62px;
    background-color: rgba(255, 255, 255, 0.5);
    transition: left 200ms;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
  `;

  const changeValue = useCallback(
    (newValue: RsvpValue) => {
      props.onChange(newValue);
      setValue(newValue);
    },
    [value]
  );

  const circlesCss = css`
    position: absolute;
    top: -30px;
    left: -20px;
    width: 110px;
    height: 110px;
    z-index: 3000;
    opacity: ${value === "Ne sait pas" ? 1 : 0};
    transition: opacity 500ms;
    pointer-events: none;
  `;

  function generateCircleCss(color: string, delay: number) {
    return css`
      ${Css.absoluteFill}
      border-radius : 55px;
      border: 3px solid ${rgba(color, 0.5)};
      transform: scale(0);
      animation: circle1 2000ms infinite ${delay}ms;
      @-webkit-keyframes circle1 {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          transform: scale(1);
          opacity: 0;
        }
      }
    `;
  }

  const circle1Css = generateCircleCss("#D12229", 0);
  const circle2Css = generateCircleCss("#F68A1E", 100);
  const circle3Css = generateCircleCss("#FDE01A", 200);
  const circle4Css = generateCircleCss("#007940", 300);
  const circle5Css = generateCircleCss("#24408E", 400);
  const circle6Css = generateCircleCss("#732982", 500);

  return (
    <div css={container}>
      <div css={bgCss} />
      <div css={partsCss}>
        <div css={circlesCss}>
          <div css={circle1Css} />
          <div css={circle2Css} />
          <div css={circle3Css} />
          <div css={circle4Css} />
          <div css={circle5Css} />
          <div css={circle6Css} />
        </div>
        <button css={partCss} onClick={() => changeValue("Oui !")}>
          <Text center>Oui !</Text>
        </button>
        <button css={partCss} onClick={() => changeValue("Ne sait pas")}>
          <Text center>-</Text>
        </button>
        <button css={partCss} onClick={() => changeValue("Non")}>
          <Text center>Non</Text>
        </button>
      </div>
      <div css={spotCss} />
    </div>
  );
}
