/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback } from "react";
import Css from "../utilities/Css";
import Layout from "../utilities/Layout";
import Theme from "../utilities/Theme";
import Typo from "../utilities/Typo";

type ButtonProps = {
  label: string;
} & (
  | { submit: true }
  | { onClick: () => any }
  | { href: string; target?: string }
);

export default function Button(props: ButtonProps) {
  const theme = Theme.useTheme();
  const containerStyle = css`
    ${Css.buttonReset}
    ${Typo.button}
    background: #000000; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #434343,
      #000000
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #434343,
      #000000
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: white;
    border-radius: 4px;
    padding-left: ${Layout.P}px;
    padding-right: ${Layout.P}px;
    padding-top: ${Layout.P / 2}px;
    padding-bottom: ${Layout.P / 2}px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    transition: transform 200ms;
    &:hover {
      transform: scale(1.1);
    }
  `;

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if ("onClick" in props) props.onClick();
    },
    ["onClick" in props ? props.onClick : null]
  );

  if ("href" in props) {
    return (
      <a href={props.href} target={props.target} css={containerStyle}>
        {props.label}
      </a>
    );
  } else {
    return (
      <button
        type={"submit" in props ? "submit" : "button"}
        css={containerStyle}
        onClick={onClick}
      >
        {props.label}
      </button>
    );
  }
}
