/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Css from "../utilities/Css";
import Button from "./Button";
import Spacer from "./Spacer";
import Text from "./Text";

type SplashProps = {
  message: string;
  reload?: boolean;
};

export default function Splash(props: SplashProps) {
  const containerCss = css`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    ${Css.centerContent};
    flex-direction: column;
    z-index: 1000;
  `;

  const imageCss = css`
    width: 280px;
  `;

  return (
    <div css={containerCss}>
      <img css={imageCss} src="noms.png" alt="Valentin et Benjamin" />
      <Spacer />
      <Text center>{props.message}</Text>
      <br />
      <br />
      {props.reload ? (
        <Button onClick={() => window.location.reload()} label="Réessayer" />
      ) : null}
    </div>
  );
}
