/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import "reset-css";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import Css from "../utilities/Css";
import "../utilities/Data";

export default function Outro() {
  const containerCss = css`
    ${Css.container};
  `;

  const nomsCss = css`
    width: 50%;
  `;

  return (
    <div css={containerCss}>
      <Spacer.Double />
      <Text typo="heading">😘😘</Text>
      <img src="signature.png" css={nomsCss} />
      <Spacer.Double />
      <Spacer.Double />
    </div>
  );
}
