/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import * as Sentry from "@sentry/react";
import "reset-css";
import Address from "./blocks/Address";
import Cash from "./blocks/Cash";
import Chat from "./blocks/Chat";
import FullPlanning from "./blocks/FullPlanning";
import Intro from "./blocks/Intro";
import Mairie from "./blocks/Mairie";
import Message from "./blocks/Message";
import Outro from "./blocks/Outro";
import Planning from "./blocks/Planning";
import Playlist from "./blocks/Playlist";
import Presents from "./blocks/Presents";
import Rsvp from "./blocks/Rsvp";
import RsvpNext from "./blocks/RsvpNext";
import SaveTheDate from "./blocks/SaveTheDate";
import Container from "./components/Container";
import Photos from "./components/Photos";
import Splash from "./components/Splash";
import "./utilities/Data";
import Data from "./utilities/Data";
import Typo from "./utilities/Typo";

export default function App() {
  const globalCss = css`
    body {
      ${Typo.body}
    }
    * {
      box-sizing: border-box;
    }
    .loader-hidden {
      display: none;
    }
    .loader-active {
      display: block;
    }
  `;

  const loading = Data.useLoading();

  if (loading === null) {
    return <Splash message="Merci de patienter..." />;
  } else if (loading === false) {
    return (
      <Splash message="Utilisez le lien recu par texto pour continuer" reload />
    );
  }

  return (
    <Sentry.ErrorBoundary
      fallback={
        <Splash
          message="Oups... Petit problème... Réessayez plus tard :)"
          reload
        />
      }
    >
      <Global styles={globalCss} />
      <div className="App">
        <Photos />
        <Container>
          <Intro />
          <Mairie />
          <FullPlanning />
          <Planning />
          <RsvpNext />
          <Address />
          <Cash />
          <Playlist />
          <Presents />
          <Message />
          <Chat />

          <SaveTheDate />
          <Rsvp />





          <Outro />
        </Container>
      </div>
    </Sentry.ErrorBoundary>
  );
}
