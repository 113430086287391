/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Layout from "../utilities/Layout";

type SpacerFreeProps = {
  size: number;
};
function SpacerFree(props: SpacerFreeProps) {
  const containerCss = css`
    width: ${props.size}px;
    height: ${props.size}px;
    flex-shrink: 0;
  `;
  return <div css={containerCss} />;
}

const Spacer = () => <SpacerFree size={Layout.S} />;
Spacer.Free = SpacerFree;
Spacer.Thin = () => <SpacerFree size={6} />;
Spacer.Half = () => <SpacerFree size={Layout.S / 2} />;
Spacer.Double = () => <SpacerFree size={Layout.S * 2} />;
Spacer.Grow = () => (
  <div
    css={css`
      flex-grow: 1;
    `}
  />
);

export default Spacer;
