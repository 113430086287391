import { css } from "@emotion/react";

const heading = css`
  font-family: "Outfit", sans-serif;
  font-weight: 100;
  font-size: 40px;
  line-height: ;
`;

const subheading = css`
  font-family: "Outfit", sans-serif;
  font-weight: 900;
  font-size: 20px;
  text-transform: uppercase;
`;

const body = css`
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: ;
`;

const total = css`
  font-family: "Outfit", sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: ;
`;

const button = css`
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: ;
  text-transform: uppercase;
`;

const step = css`
  font-family: "Outfit", sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 21.6px;
`;

const minor = css`
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const Typo = {
  heading,
  subheading,
  total,
  body,
  button,
  step,
  minor,
};

export type TypoName = keyof typeof Typo;

export default Typo;
