/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useState } from "react";
import "reset-css";
import Button from "../components/Button";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import contextualize from "../utilities/contextualize";
import Css from "../utilities/Css";
import "../utilities/Data";
import Data from "../utilities/Data";
import Typo from "../utilities/Typo";

export default function Address() {
  const infos = Data.useInfos();

  const [message, setMessage] = useState(infos.famille.fields.Adresse || "");

  const textareaCss = css`
    ${Typo.body}
    width : 100%;
    height: 200px;
    outline: 2px solid rgba(255, 255, 255, 0.4);
    outline-offset: -2px;
    border: none;
    border-radius: 4px;
    background: #e0eafc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 10px;
    text-align: center;
    width: 100%;
  `;

  const formCss = css`
    ${Css.container}
  `;

  const responseCss = css`
    ${Css.container}
    background: #e0eafc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #cfdef3,
      #e0eafc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    text-align: center;
  `;

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await Data.updateFamilleValue(infos.famille.id, "Adresse", message)
      alert("C'est noté !");
    },
    [message]
  );

  return (
    <Panel
      title="Gardons le contact !"
      paddingBottom
      paddingSides
      lastUpdate={"Oui"}
    >
      <Text center>
        {contextualize(infos, { plural: "Donnez-nous votre", one: "Donne-nous ton" })}
        adresse postale pour qu'on  {contextualize(infos, { plural: "vous envoie", one: "t'envoie" })} un petit quelque après le mariage... Promis, on s'en occupe avant 2025 ! 🤣
      </Text>
      <Spacer />
      <form css={formCss} onSubmit={onSubmit}>
        <textarea
          css={textareaCss}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Spacer />
        <Button submit label="Valider" />
      </form>
      <Spacer />
    </Panel>
  );
}
