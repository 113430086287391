import { useMemo } from "react";

function useConcat(
  strs: Array<string>,
  middleJoin: string,
  endingJoin: string
) {
  return useMemo(() => {
    if (strs.length === 0) return "";
    if (strs.length === 1) return strs[0];
    const firsts = strs.slice(0, strs.length - 1);
    const last = strs[strs.length - 1];
    return `${firsts.join(middleJoin)} ${endingJoin} ${last}`;
  }, [...strs, middleJoin, endingJoin]);
}

export default useConcat;
