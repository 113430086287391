/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Record } from "airtable";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import React, { Fragment, useCallback, useState } from "react";
import "reset-css";
import Button from "../components/Button";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import contextualize from "../utilities/contextualize";
import Css from "../utilities/Css";
import "../utilities/Data";
import Data, { Chat } from "../utilities/Data";
import Layout from "../utilities/Layout";
import Typo from "../utilities/Typo";

const messageCss = css`
  background: #d3cce3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #e9e4f0,
    #d3cce3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #e9e4f0,
    #d3cce3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: ${Layout.P}px;
  border-radius: 10px;
  margin-top: 10px;
`;

const textareaCss = css`
  ${Typo.body}
  width : 100%;
  height: 100px;
  outline: 2px solid rgba(255, 255, 255, 0.4);
  outline-offset: -2px;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  text-align: left;
  width: 100%;
`;

const buttonWrapperCss = css`
  ${Css.container}
`;

export default function Forum() {
  const infos = Data.useInfos();

  const messagesCss = css`
    width: 100%;
  `;

  const [message, setMessage] = useState<string>("");

  const sendMessage = useCallback(async () => {
    await Data.addChat(infos.invitation, message);
    setMessage("");
  }, [message]);

  const buttonCss = css`
    ${Css.buttonReset}
  `;

  return (
    <Panel title="Discussion" paddingBottom paddingSides>
      <Text center>
        {contextualize(infos, { plural: "Partagez votre", one: "Partage ta" })}{" "}
        bonne humeur avec tous les invités dans ce petit chat
      </Text>
      <Spacer.Half />
      <div css={messagesCss}>
        <div css={messageCss}>
          <Text typo="minor" color="rgba(0,0,0,0.5)">
            Valentin, le 16 septembre 2020
          </Text>
          <Spacer.Free size={4} />
          <Text>Chaton, veux-tu devenir mon mari ? 🙄 💕 </Text>
        </div>
        <div css={messageCss}>
          <Text typo="minor" color="rgba(0,0,0,0.5)">
            Benjamin, le 16 septembre 2020
          </Text>
          <Spacer.Free size={4} />
          <Text>Bah oui mon chaton ! 🤩 💕 </Text>
        </div>
        {infos.chats.map((chat) => (
          <ChatMessage key={chat.id} chat={chat} />
        ))}
        <div css={messageCss}>
          <Text typo="minor" color="rgba(0,0,0,0.5)">
            {`${infos.famille.fields.Libellé}, le ${format(
              new Date(),
              "d MMMM yyyy",
              { locale: fr }
            )}`}
          </Text>
          <Spacer.Half />
          <textarea
            css={textareaCss}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <Spacer.Half />
          <div css={buttonWrapperCss}>
            <Button label="Publier" onClick={sendMessage} />
          </div>
        </div>
      </div>
    </Panel>
  );
}

type ChatMessageProps = {
  chat: Record<Chat>;
};

function ChatMessage(props: ChatMessageProps) {
  const { chat } = props;

  const infos = Data.useInfos();
  const isMine = infos.famille.fields.Libellé === chat.fields.Famille[0];

  const [message, setMessage] = useState<string>(chat.fields.Contenu);
  const [edit, setEdit] = useState<boolean>(false);

  const toggleEdit = useCallback(() => {
    setEdit((e) => (isMine ? !e : e));
  }, [isMine]);

  const sendMessage = useCallback(async () => {
    if (message) {
      await Data.editChat(chat.id, message);
      setEdit(false);
    } else {
      await Data.deleteChat(chat.id);
      setEdit(false);
    }
  }, [message]);

  return (
    <div css={messageCss} onClick={toggleEdit}>
      <Text typo="minor" color="rgba(0,0,0,0.5)">
        {chat.fields["Famille"][0]}, le{" "}
        {format(new Date(chat.fields["Date"]), "d MMMM yyyy", {
          locale: fr,
        })}
      </Text>
      <Spacer.Free size={4} />
      {!edit ? (
        <Text>{chat.fields["Contenu"]}</Text>
      ) : (
        <Fragment>
          <textarea
            css={textareaCss}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          ></textarea>
          <Spacer.Free size={4} />
          <div css={buttonWrapperCss}>
            <Button
              label={message ? "Modifier" : "Effacer"}
              onClick={sendMessage}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}
