import { EventEmitter } from "events";
import { useCallback, useEffect, useState } from "react";

const emitter = new EventEmitter();
emitter.setMaxListeners(0);

export default class Updater {
  private eventName: string;

  constructor() {
    this.eventName = `change${Math.random()}`;
  }

  update() {
    emitter.emit(this.eventName);
  }

  onUpdate(cb: () => void): () => void {
    emitter.on(this.eventName, cb);
    return () => emitter.off(this.eventName, cb);
  }

  useValue<TValue>(getter: () => TValue): TValue {
    // eslint-disable-next-line
    const wrappedGetter = useCallback(() => ({ _v: getter() }), []);
    // eslint-disable-next-line
    const [value, setValue] = useState(wrappedGetter);
    // eslint-disable-next-line
    useEffect(() => {
      const cb = () => setValue(wrappedGetter);
      const unsub = this.onUpdate(cb);
      return () => unsub();
    }, []);
    return value._v;
  }

  useReaction(reaction: () => void) {
    //eslint-disable-next-line
    useEffect(() => {
      const unsub = this.onUpdate(reaction);
      return () => unsub();
    }, []);
  }
}
