/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import "reset-css";
import "../utilities/Data";

export default function Divider() {
  const containerCss = css`
    height: 1px;
    width: 50%;
    background-color: currentColor;
    margin-left: auto;
    margin-right: auto;
  `;
  return <div css={containerCss} />;
}
