/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "reset-css";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import "../utilities/Data";
import Data from "../utilities/Data";
import pluralize from "../utilities/pluralize";
import useConcat from "../utilities/useConcat";

export default function SaveTheDate() {
  const config = Data.useInfos();

  const containerCss = css`
    background-image: url(rainbow.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
  `;

  const names = useConcat(
    config.personnes.map((p) => p.fields.Prénom),
    ", ",
    "et"
  );

  return (
    <Panel
      title="Save the date !"
      paddingBottom
      paddingSides
      containerCss={containerCss}
    >
      <Text center typo="subheading">
        {names}
      </Text>
      <Spacer />
      <Text center>Depuis notre petit caillou à l'autre bout du monde,</Text>
      <Text center>
        nous sommes heureux de{" "}
        {pluralize(config.pluriel, "t'inviter", "vous inviter")} à célébrer
        notre mariage qui aura lieu le
      </Text>
      <Spacer />
      <Text typo="subheading" center>
        Samedi 10 septembre 2022, en début d'après midi
      </Text>
      <Spacer />
      <Text center>
        Nous sommes en train de choisir le lieu où nous nous rassemblerons. Il
        sera situé
      </Text>
      <Spacer />
      <Text typo="subheading" center>
        dans un restaurant
      </Text>
      <Spacer.Half />
      <Text typo="subheading" center>
        en centre ville de Nantes
      </Text>
      <Spacer />
      <Text center>
        La soirée se poursuivra en ville, en fonction des présents et des envies
        de chacun.
      </Text>
    </Panel>
  );
}
