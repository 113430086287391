/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import Layout from "../utilities/Layout";

type ContentProps = PropsWithChildren<{}>;

export default function Content(props: ContentProps) {
  const style = css`
    padding-left: ${Layout.S}px;
    padding-right: ${Layout.S}px;
  `;
  return <div css={style}>{props.children}</div>;
}
