/** @jsxImportSource @emotion/react */
import "reset-css";
import Panel from "../components/Panel";
import Spacer from "../components/Spacer";
import Text from "../components/Text";
import "../utilities/Data";

export default function Mairie() {
  return (
    <Panel title={`Et c'est un double "Oui !"`} paddingBottom paddingSides lastUpdate="oui">
      <Text center>
        La mariage en mairie a eu lieu le 3 septembre à l'Ile d'Yeu, en compagnie de notre cercle familial rapproché et
        de nos témoins.
      </Text>
      <Spacer />
      <Text center typo="minor">
        Merci pour tous les messages, auxquels on n'a pas trop pris le temps de répondre !
      </Text>
    </Panel>
  );
}
